/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "sv",
      "3166-1": "SE",
      "IETF": "sv-SE",
    },
    "CURRENCY": "SEK",
    "CURRENCY_SHORT": "kr",
    "POINTS": "poäng",
    "TYPEKIT_ID": "thm8nca",
  },
  "COOKIE_CONSENT": {
    "NOTICE": "För att förbättra och anpassa din upplevelse av våra digitala tjänster använder vi cookies. Genom att fortsätta använda webbplatsen godkänner du användning av cookies.",
    "ALL": "Tillåt alla",
    "NECESSARY": "Tillåt nödvändiga",
    "SELECTED": "Tillåt urval",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nödvändiga",
    "STATISTICAL": "Statistiska",
    "MARKETING": "Marknadsföring",
  },
  "IE_NOTICE": "Du använder en webbläsare som vi inte har stöd för. Siten har begränsad funktionalitet om du använder denna webbläsaren.",
  "COMMON": {
    "SAVE_AS_PDF": "Spara som PDF",
    "PRINT": "Skriv ut",
    "CLOSE": "Stäng",
    "CANCEL": "Avbryt",
    "APPROVE": "Godkänn",
    "SEND": "Skicka",
    "READ_MORE": "Läs mer",
    "FROM": "Från",
    "TO": "Till",
    "SEE_ALL": "Se alla",
    "REMOVE": "Ta bort",
    "EDIT": "Redigera",
    "ADD": "Lägg till",
    "GO_BACK": "Gå tillbaka",
    "REGISTER": "Registrera",
    "I_ACCEPT_THE": "Jag accepterar",
    "ORDER_ID": "Ordernummer",
  },
  "LOGO": {
    "ALT": "Logo",
    "TITLE": "Gå till startsidan",
  },
  "OR": "Eller",
  "HOMEVIEW": {
    "HERO_ALT": "Awardit",
    "POPULAR_BRANDS": "Populära varumärken",
    "POPULAR_CATEGORIES": "Populära kategorier",
    "POPULAR_PRODUCTS": "Populära produkter",
    "SEE_MORE": "Se fler",
    "BECOME_CUSTOMER": "Bli kund",
    "MORE_NEWS": "Fler nyheter",
  },
  "ERRORVIEW": {
    "TITLE": "Ett fel har uppstått. Vänligen försök igen.",
  },
  "TERMSVIEW": {
    "FALLBACK_TITLE": "Villkor",
    "ERROR_FETCHING": "Sidans innehåll kunde inte laddas. Vänligen försök igen.",
    "MEMBERTAG_TITLE": "Gör ditt val",
    "MEMBERTAG_EMPLOYEE_OPTION": "Ja, jag vill att säljare på mitt företag deltar i Release Points och godkänner att de får points för kontrakt och aktiviteter insatta direkt på sitt konto.",
    "MEMBERTAG_MANAGER_OPTION": "Nej, jag vill inte att mina säljare deltar aktivt i Release Points. Points sätts in på företagskontot som jag hanterar.",
    "ERROR_POST_MEMBERTAG": "Det gick ej att skicka in ditt val för tillfället",
  },
  "ABOUTVIEW": {
    "TITLE": "Om oss",
  },
  "LOGIN": {
    "TITLE": "Logga in",
    "USERNAME": "Användarnamn",
    "USERNAME_EMAIL": "Användarnamn eller e-post",
    "ORGNR": "Organisationsnummer",
    "PASSWORD": "Lösenord",
    "SUBMIT": "Logga in",
    "FORGOT_PASSWORD": "Glömt ditt lösenord?",
    "RESET_PASSWORD": "Tryck här",
    "CREATE_ACCOUNT_HEADING": "Har du inget konto?",
    "CREATE_ACCOUNT": "Skapa nytt konto",
  },
  "RECORDS": {
    "ACCOUNTING_RECORDS": "Bokföringsunderlag",
    "TEXT": "Här kan du se bokföringsunderlag för tidigare månader. Bokföringsunderlaget finns tillgängligt först när föregånde månad har passerat.</br> Om du önskar bokföringsunderlag från 2021 eller tidigare – vänligen kontakta vår <a href='%link' rel='noopener noreferrer' target='_blank'>kundtjänst.</a>",
    "LINK_TEXT": "Om du har frågor – vänligen kontakta vår <a href='%link' rel='noopener noreferrer' target='_blank'>kundtjänst.</a>",
    "EMPTY": "Inget bokföringsunderlag är tillgängligt ännu.",
    "BUTTON": "Visa bokföringsunderlag",
  },
  "MONTHS": {
    "1": "Januari",
    "2": "Februari",
    "3": "Mars",
    "4": "April",
    "5": "Maj",
    "6": "Juni",
    "7": "Juli",
    "8": "Augusti",
    "9": "September",
    "10": "Oktober",
    "11": "November",
    "12": "December",
  },
  "RESET_PASSWORD": {
    "EMAIL": "Mailadress",
    "SUBMIT": "Skicka mail",
  },
  "NEW_PASSWORD": {
    "TITLE": "Ange nytt lösenord",
    "PASSWORD": "Nytt lösenord",
    "PASSWORD_REPEAT": "Upprepa nytt lösenord",
    "SUBMIT": "Spara nytt lösenord",
  },
  "REGISTER": {
    "FIRSTNAME": "Förnamn",
    "LASTNAME": "Efternamn",
    "EMAIL": "E-mail",
    "PHONE": "Telefon",
    "COMPANY": "Företag",
    "ORG_NUMBER": "Organisationsnummer",
    "TOTAL_MEMBERS": "Antal anställda",
    "STREET_ADDRESS": "Gatuadress",
    "ZIPCODE": "Postnummer",
    "CITY": "Stad",
    "OTHER": "Övrigt",
    "PNR": "Personnummer",
    "GENDER": "Kön",
    "USER_ID": "Användar-ID",
    "SHOP": "Butik",
    "DISTRICT": "Distrikt",
    "COUNTRY": "Land",
    "DATE_OF_BIRTH": "Födelsedatum",
    "PASSWORD": "Lösenord",
    "REPEAT_PASSWORD": "Upprepa lösenord",
    "TERMS": "Jag accepterar <a href='%link'>villkoren</a>",
    "EXISITING_ACCOUNT": "Har du redan ett konto?",
  },
  "AFFILIATE": {
    "CTA": {
      "ACTIVATED": "Aktiverad",
      "INACTIVE": "Aktivera",
      "PROCESSING": "Väntar",
    },
  },
  "EARN": {
    "SEARCH": "Sök på erbjudande",
    "CLEAR_SEARCH": "Rensa sökfältet",
    "SHOW_LESS": "Visa mindre",
    "SHOW_MORE": "Läs mer",
    "START": "Start",
  },
  "BRAND": {
    "SEARCH": "Sök på varumärken",
    "CLEAR_SEARCH": "Rensa sökfältet",
    "TOTAL_COUNT": "Visar %total produkt(er)",
    "EMPTY": "Det finns inga produkter att visa just nu",
  },
  "ACCOUNT": {
    "LOGIN": "Logga in",
    "LOGOUT": "Logga ut",
    "MY_ACCOUNT": "Mitt konto",
    "USER": "Användare",
    "MEMBERSHIP": "Medlemsskap",
    "MEMBER_NUMBER": "Medlemsnr",
    "SHIPPING_ADDRESS": "Leveransadress",
    "ELAPSED_POINTS": "Utgående %pointsLabel",
    "ELAPSING_POINTS_INFO": "Du har %points %pointsLabel som går ut den %date (%dateRelative)",
    "EDIT_INFO": "Ändra mina uppgifter",
    "WISHLIST": "Önskelista",
    "EMAIL": "E-mail",
    "NO_PHONE_NUMBER": "Inget telefonnummer",
    "BALANCE": "Saldo",
    "EXPIRES": "Förfaller",
    "DATE": "Datum",
    "SEE_ALL": "Se alla",
    "EDIT": "Ändra",
    "OPEN": "Öppna",
    "YOUR_POINTS": "Dina %pointsLabel",
    "NEWSLETTER": "Jag vill ha mail om nyheter och erbjudanden",
    "SAVING": "Sparar...",
    "SAVE_AND_CLOSE": "Spara och stäng",
    "CURRENT_PASSWORD": "Befintligt lösenord",
    "NEW_PASSWORD": "Nytt lösenord",
    "CONFIRM_NEW_PASSWORD": "Bekräfta nytt lösenord",
    "GENDER": {
      "MALE": "Man",
      "FEMALE": "Kvinna",
      "UNSPECIFIED": "Ospecificerat",
    },
    "MEMBER_GROUP": {
      "USERS": "Användare",
      "OWNER": "Ägare",
      "CAN_LOGIN": "Kan logga in",
      "ADD_MEMBER": "Lägg till medlem",
      "EDIT_MEMBER": "Redigera medlem",
      "SAVE_CHANGES": "Spara ändringar",
      "REMOVE_MEMBER": "Ta bort medlem?",
      "REMOVE_MEMBER_CONFIRM": "Är du säker på att du vill ta bort %member?",
      "FULL_NAME": "Fullständigt namn",
      "MEMBER_ID": "Medlems-ID",
      "SIGN_INS": "Inloggningar",
      "POINTS": "%pointsLabel",
      "NO_MEMBERS": "Gruppen saknar medlemmar",
      "TRANSFER_POINTS": {
        "FORMHEADER": "Utdelning av %pointsLabel",
        "COMMENT": "Kommentar",
        "POINTS_AVAILABLE": "Tillgängliga %pointsLabel",
        "POINTS_HANDED_OUT": "%pointsLabel som delas ut",
        "POINTS_LEFT": "%pointsLabel kvar",
        "CHOOSE_ALL": "Välj alla",
        "DEFAULT_MESSAGE": "Överföring",
        "HISTORY": {
          "HEADER": "Tidigare utdelningar",
          "DATE": "Datum",
          "RECIPIENTS": "Mottagare",
          "DISTRIBUTED": "Utdelat",
        },
      },
      "TRANSACTIONS": {
        "DISPLAY": "Visa transaktioner",
        "HEADER": "Transaktioner och saldo",
        "REGISTRATION_DATE": "Registreringsdatum:",
        "ACTIVATION_DATE": "Aktiveringsdatum:",
        "CURRENT_BALANCE": "Aktuellt saldo:",
        "EXPIRING_POINTS": "Antal poäng som utgår nästa månad (%date):",
        "ACCUMULATED_BALANCE": "Ackumulerat saldo:",
        "NO_TRANSACTIONS": "Det finns inga transaktioner tillgängliga för denna medlemmen.",
      },
    },
  },
  "RECRUIT_A_FRIEND": {
    "LABEL_NAME": "Namn",
    "LABEL_EMAIL": "E-mail",
    "LABEL_MESSAGE": "Meddelande",
    "LABEL_PHONE": "Telefon",
  },
  "TINK": {
    "ACTIVE": "Aktiv",
    "INACTIVE": "Inaktiv",
    "REMOVE_PROVIDER": "Ta bort",
    "RENEW_PROVIDER": "Förnya",
    "REMAINING_DAYS": {
      "SINGULAR": "1 dag kvar",
      "PLURAL": "%days dagar kvar",
    },
    "REMOVE_CONFIRM": "Är du säker på att du vill ta bort kopplingen till \"%name\"?",
    "CONFIRM": {
      "TITLE": "Ta bort koppling",
      "OK": "OK",
      "CANCEL": "Avbryt",
    },
    "MODAL": {
      "TITLE": "Förnya ditt godkännande",
      "SUBTITLE_PART1": "För att du fortsättningsvis ska kunna få %pointsLabel för dina köp behöver du förnya godkännandet för hantering av dina transaktioner.",
      "SUBTITLE_PART2": "Förnya godkännande av hantering av dina transaktioner här eller gör det via",
      "YOUR_ACCOUNT": "ditt konto",
    },
  },
  "FORM_LIST": {
    "REMOVE_DIALOGUE": {
      "TITLE": "Ta bort annons",
      "MESSAGE": "Är du säker på att du vill ta bort annonsen?",
      "CONFIRM": "Ta bort",
      "CANCEL": "Avbryt",
    },
  },
  "POINTS": {
    "HEADER": "Överför %pointsLabel",
    "MY_POINTS": "Mina %pointsLabel",
    "INTRO": "Här kan du göra överföringar mellan dina konton",
    "TRANSFERING": "Överför...",
    "TRANSFER": "För över %pointsLabel",
    "AMOUNT_OF_POINTS": "Hur många %pointsLabel ska föras över?",
    "SELECT_ACCOUNT": "Välj konto",
    "FROM_ACCOUNT": "Från konto",
    "TO_ACCOUNT": "Till konto",
    "AMOUNT": "Saldo",
  },
  "QUIZ_ITEM": {
    "NUM_QUESTIONS": "%questions frågor",
    "POINTS": "%points %pointsLabel",
    "STATUS": {
      "CLOSED": "Stängd",
      "ANSWERED": "Besvarad",
      "UNANSWERED": "Ej besvarad",
    },
    "SHOW_ANSWER": "Visa svar",
  },
  "QUIZ": {
    "HEADING": "Fråga %step av %of",
    "INPUT_LABEL": "Ange ditt svar",
    "LIST_ALTERNATIV_PLACEHOLDER": "Välj alternativ",
    "IMAGE_INPUT_LABEL": "Ladda upp bild",
    "IMAGE_INPUT_BUTTON": "Välj fil",
    "NEXT": "Gå vidare",
    "SUBMIT": "Spara",
    "ALREADY_ANSWERED": "Du har redan svarat på den här enkäten. Nedan ser du dina svar.",
    "CLOSED": "Tiden för den här enkäten har gått ut. Du kan inte längre svara på den.",
    "EMPTY": "Det finns inget att visa",
    "SUCCESS": {
      "HEADING": "Tack för att du deltog!",
      "INFO": "Som tack så får du %points %pointsLabel att använda i vår butik.",
      "BONUS": "%points %pointsLabel har lagts till i ditt saldo, uppdateringen av saldot kommer att visas nästa gång du loggar in.",
      "BACK": "Till startsidan",
      "ALREADY_ANSWERED": "Du har redan svarat på den här enkäten.",
      "COULD_NOT_SAVE": "Kunde inte spara dina svar. Kontrollera att alla frågorna är ifyllda eller kontakta support.",
      "QUESTIONNAIRE_CLOSED": "Tiden för den här enkäten har gått ut. Du kan inte längre svara på den.",
    },
    "YOUR_ANSWER": "Ditt svar: ",
    "HISTORY_BACK": "Gör om testet",
  },
  "QUESTIONNAIRE": {
    "EMPTY_LIST": "Just nu finns inga enkäter inom kategorin",
  },
  "FILTER": {
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT": "Visa resultat",
      "SHOW_RESULT_TOTAL_MULTIPLE": "Visa %total resultat",
      "SHOW_RESULT_TOTAL_SINGLE": "Visa %total resultat",
      "CLOSE_MENU": "Stäng filtermenu",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Rensa alla filter",
      "NO_ACTIVE_FILTERS": "Du har inga aktiva filter",
      "PRICE_RANGE": "Pris (%range)",
      "WITHIN_BALANCE": "Produkter inom poängsaldo (%range)",
      "TOTAL_COUNT": "Visar %count resultat",
      "COUNT": "Aktivt filter",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filtrera på pris",
      "FILTER_BY_POINTS": "Filtrera på %pointsLabel",
      "RESET_PRICE": "Rensa",
      "PRICE_FROM": "Från",
      "PRICE_TO": "Till",
      "WITHIN_BALANCE": "Produkter inom poängsaldo",
    },
    "FILTERBAR": {
      "ALL_FILTERS": "Alla filter",
      "FILTER_BY_PRICE": "Filtrera på pris",
      "FILTER_BY_POINTS": "Filtrera på %pointsLabel",
      "PRICE_RANGE": "%min - %max %pointsLabel",
      "SORT": "Sortera",
      "TOTAL_COUNT": "Visar %total produkt(er)",
      "SORT_NAME_DOWN": "Namn: Ö-A",
      "SORT_NAME_UP": "Namn: A-Ö",
      "SORT_PRICE_DOWN": "Pris: Högt",
      "SORT_PRICE_UP": "Pris: Lågt",
      "SORT_POSITION_DOWN": "Populärast",
      "SORT_POPULAR_DOWN": "Populärast",
      "SORT_VALUE_DOWN": "Värde: Lågt",
      "SORT_VALUE_UP": "Värde: Högt",
      "SORT_RELEASE_DATE_DOWN": "Senaste",
      "SORT_LATEST_DOWN": "Senaste",
      "SORT_RELEVANCE_DOWN": "Relevans",
    },
  },
  "TRANSACTIONS": {
    "TRANSACTION_ID": "Transaktions-ID",
    "CLUB_OWNER": "Klubbägare",
    "PRODUCT_ID": "Produkt-ID",
    "QTY": "Antal",
    "COST": "Kostnad",
    "MEMBER_ID": "Medlems-ID",
    "MEMBER_NAME": "Medlemsnamn",
    "DATE": "Datum",
    "COMPANY": "Företag",
    "EMAIL": "Mail",
    "ADDRESS": "Adress",
    "POSTCODE": "Postnummer",
    "CITY": "Stad",
    "COUNTRY": "Land",
    "TELEPHONE": "Telefon",
    "ALL_TRANSACTIONS": "Alla transaktioner",
    "ONLY_INCOMING": "Endast inkommande transaktioner",
    "ONLY_OUTGOING": "Endast utgående transaktioner",
    "SEARCH": "Sök efter transaktion",
    "ID": "ID",
    "TRANSACTION": "Transaktion",
    "POINTS": "%pointsLabel",
    "RECEIPT": "Kvitto",
    "RECEIPT_DETAILS": "Se kvitto",
    "POINTS_EXPIRES": "%pointsLabel går ut",
    "REFUND": "Återbetalning",
  },
  "LEADERBOARD": {
    "TITLE": "Leaderboard",
    "SUBTITLE_PART1": "Här kan du se din placering i rankning måt vår konferensresa. Lycka till",
    "SUBTITLE_PART2": "Din placering & summering",
    "POSITION_OF_CONTESTANTS": "av %amount %contestants",
    "DISTRIKT_DROPDOWN": "Välj ett leaderboard: ",
    "MISSING_USER_DATA": "Din användare saknar poäng för vald period.",
    "MISSING_DATA": "Ingen data tillgänglig för vald period.",

    "HERO": {
      "SHOW": "Visa",
      "PREVIOUS_MONTH": "Tidigare månad",
      "MONTH": "Månad",
      "YEAR": "År",
      "YEAR_ALT": "Årtal",
    },
  },
  "CALCULATOR": {
    "POINTS": "%pointsLabel",
    "PLACEHOLDER_AMOUNT": "Skriv in finansierat belopp (SEK)",
    "PLACEHOLDER_COUNT": "Antal kontrakt…",
    "PLACEHOLDER_INTEREST": "Ränteupplägg",
    "DROPDOWN_INTEREST": "Räntebärande Avtal",
    "DROPDOWN_INTERESTFREE": "Räntefritt Avtal",
    "ERROR": "Fyll i alla fält",
  },
  "REPORTS": {
    "TITLE": "Rapporter",
    "HISTORY_BUTTON": "Historik",
    "BACK": "Tillbaka",
    "EMPTY": "Det finns inga rapporter att visa",
    "ERROR": "Kunde inte ladda rapporter.",
    "HISTORY": {
      "TITLE": "Historik",
      "SUBTITLE": "Här ser du alla inskickade rapporter.",
      "TRANSACTIONS": "Transaktionshistorik",
    },
    "APPROVAL": {
      "TITLE": "Klarmarkering",
      "SUBTITLE_PART1": "Här klarmarkerar du ordrarna",
      "SUBTITLE_PART2": "klarmarkering",
      "SUBMIT": "Klarmarkera",
    },
    "DOCUMENT": "Dokument",
  },
  "WISHLIST": {
    "TITLE": "Önskelista",
    "BACK": "Gå till mitt konto",
  },
  "NEWS": {
    "TITLE": "Nyheter",
    "LATEST": "Senaste nyheterna",
    "SHOW_ALL": "Visa alla nyheter",
    "NO_NEWS": "Det finns tyvärr inga nyheter att läsa just nu.",
  },
  "OFFERS": {
    "LINK": "Gå till erbjudantet",
    "EMPTY": "Det finns inga erbjudanden för tillfället",
  },
  "GALLERY": {
    "CLOSE": "Stäng bildvisning",
  },
  "IMAGE_UPLOADER": {
    "TITLE": "Alla foton",
    "SHOW_ALL": "Se fler foton",
    "UPLOAD_IMAGE": "Ladda upp foto",
    "UPLOAD_IMAGE_BTN": "Ladda upp",
    "LATEST": "Senaste foton",
    "CHOOSE_FILE": "Välj fil",
    "EMPTY": "Inga foton att visa",
  },
  "ADDRESS": {
    "FIRSTNAME": "Förnamn",
    "LASTNAME": "Efternamn",
    "STREET_1": "Gatuadress 1",
    "STREET_2": "Gatuadress 2 (frivilligt)",
    "POSTCODE": "Postnummer",
    "CITY": "Stad",
    "COUNTRY": "Land",
    "EMAIL": "E-mail",
    "TELEPHONE": "Telefon",
    "COMPANY": "Företag",
  },
  "VALUE": "Värde",
  "TRY_AGAIN": "Försök igen",
  "CART": {
    "TITLE": "Varukorg",
    "EMPTY": "Varukorgen är tom",
    "START_SHOPPING": "Börja handla",
    "CTA": "Gå till kassan",
    "ALT": "Ändra i varukorg",
    "EDIT": "Ändra i varukorg",
    "TO_PAY": "Att betala",
    "INCREMENT": "Öka kvantitet",
    "DECREMENT": "Minska kvantitet",
    "QUANTITY": "Kvantitet",
    "OPEN_CART": "Öppna varukorgen",
    "CLOSE": "Stäng",
    "CLOSE_CART": "Stäng kassan",
    "CONTINUE_SHOPPING": "Fortsätt handla",
    "PRODUCTS": "Produkter",
    "SHIPPING": "Frakt",
    "SUBTOTAL": "Summa",
    "TOTAL": "Totalt",
    "GRANDTOTAL": "Totalsumma",
    "YOUR_BALANCE": "Ditt %pointsLabel-saldo",
    "SUMMARY": "Summering",
    "MINIMIZE_SUMMARY": "Minimera summering",
    "VIEW_DETAILS": "Visa summering",
    "TO_CHECKOUT": "Gå till kassan",
    "MOVE_TO_WISHLIST": "Flytta till önskelista",
    "REMOVE_PRODUCT": "Ta bort produkt",
    "INSUFFICIENT_FUNDS_HEADER_POSITIVE": "Ditt ordervärde är godkänt.",
    "INSUFFICIENT_FUNDS_HEADER_NEGATIVE": "Ditt ordervärde är för högt",
    "INSUFFICIENT_FUNDS_BODY": "För att du ska kunna genomföra ditt köp, så måste minst %spendingLimit% av din order betalas med %pointsLabel. Om ditt ordervärde är högre än vad som tillåts för att genomföra köpet så måste du ta bort produkter för att fortsätta.",
    "INSUFFICIENT_FUNDS_POINTS_ONLY_BODY": "Ditt varukorgsvärde är högre än ditt %pointsLabel-saldo. En eller flera av dina produkter går inte att delbetala. Du måste ta bort en eller flera av dina produkter i din varukorg för att slutföra köpet.",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "Produkterna i din varukorg går endast att köpa med poäng intjänade hos %partnerName.",
    "INSUFFICIENT_FUNDS_BOTTOM_LABEL": "Du saknar %points",
    "INSUFFICIENT_FUNDS_SUMMARY": "Varukorgen överskrids med",
  },
  "CHECKOUT": {
    "TITLE": "Kassa",
    "NEED_HELP": "Behöver du hjälp? Vi finns här för dig!",
    "CONTACT_CUSTOMER_SERVICE": "Kontakta kundtjänst",
    "TO_PAYMENT": "Gå till betalning",
    "CHOOSE_PAYMENT_METHOD": "Vidare till betalning",
    "POINTS_TITLE": "Betala med %pointsLabel",
    "POINTS_BALANCE": "Du har %points. Dra i reglaget för att välja hur många %pointsLabel du vill använda. Du kan delbetala resterande belopp (upp till %currencyLimit% av ordervärdet) med kreditkort.",
    "POINTS_BALANCE_JAVA": "Du har %points. %javatext",
    "AMOUNT_TO_PAY": "Att betala",
    "POINTS": "%pointsLabel",
    "PAY_WITH_CARD": "Betala med kort",
    "EDIT": "Ändra",
    "PAYMENT_AND_DELIVERY": "Betalning & leverans",
    "DISTRIBUTION": "Fördelning",
    "SENT_TO": "Skickas till",
    "ADDRESSES_TITLE": "Leveransadress",
    "EDIT_ADDRESS": "Ändra adress",
    "ADDRESS_MINIMIZE": "Minimera",
    "CONFIRM_ORDER": "Slutför beställning",
    "CONTINUE_SHOPPING": "Börja handla",
    "PAYMENT_TITLE": "Betalning",
    "CARD_PAYMENT": "Kortbetalning",
    "I_ACCEPT_THE": "Jag accepterar",
    "TERMS": "köpvillkoren",
    "A_TERMS": "Köpvillkor",
    "ACCORDION_HEADER_CART": "Varukorg",
    "EX_TAX": "Exkl. moms",
    "INC_TAX": "Inkl. moms",
    "EX_TAX_FULL": "Exklusive moms",
    "INC_TAX_FULL": "Inklusive moms",
    "SUBTOTAL": "Delsumma",
    "PAYMENT_DISTRIBUTION": "Uppdelad betalning",
    "AND": "och",
    "OR": "eller",
    "MODE_OF_DELIVERY": "Leveranssätt",
    "VIRTUAL_DELIVERY": "Din beställning levereras digitalt via email.",
    "ADDRESS": {
      "ADDRESS": "Adress",
      "SHIPPING": "Leveransadress",
      "BILLING": "Kvittoadress",
      "SHIP_TO_SAME_ADDRESS": "Samma adress på kvitto",
      "EMPTY_ADDRESS": "Giltig adress eller uppgifter saknas",
      "CONTACT_INFO": "Kontaktuppgifter",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Varukorg",
        "LONG": "Varukorg",
      },
      "SHIPPING": {
        "SHORT": "Leverans",
        "LONG": "Kontrollera din adress",
      },
      "PAYMENT": {
        "SHORT": "Fördela betalning",
        "LONG": "Fördela din betalning",
      },
      "OVERVIEW": {
        "SHORT": "Betala",
        "LONG": "Översikt",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "Hur vill du betala?",
    "USE_PAY": "Betala nu",
    "USE_CARD": "Betala med kort",
    "USE_SAVED_CARD": "Betala med sparat kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "SUCCESS": {
    "TITLE": "Beställning registrerad",
    "SUB_TITLE": "Ordernummer %number",
    "QTY": "Antal: %qty",
    "STEPS": {
      "TITLE": "Tack för din beställning!",
      "HEADING": "Beställning registrerad",
      "WHAT_NOW": "Så vad händer nu?",
      "LABEL": "Din beställning",
      "ORDER_ID": "Ordernummer %id",
      "QUESTIONS": {
        "HEADING": "Har du en fråga om din beställning?",
        "LABEL": "Gå till kundtjänst",
        "URL": "/faq",
      },
      "CONFIRMATION": {
        "1": {
          "HEADING": "Bekräftelse",
          "TEXT": "Tack för din beställning! En orderbekräftelse kommer att skickas till angiven mailadress: %email inom kort. Om du inte får mailet, vänligen kontrollera att det inte hamnat i skräpposten.",
        },
        "2": {
          "HEADING": "Leverans",
          "TEXT": "Leverans av din order sker från eget lager eller från någon av våra samarbetspartners. Detta innebär att beställningen kan levereras i flera olika försändelser. Vid leverans från eget lager skickas ordern när samtliga produkter finns i lager. Beställningar av produkter från eget lager levereras normalt inom 3-5 arbetsdagar. Vid leverans från någon av våra samarbetspartners skickas ordern direkt från deras lager. Beställningar av produkter från våra samarbetspartners levereras normalt inom 1-2 veckor.<br/><br/>I orderbekräftelsen som skickas till din mailadress inom kort ser du hur din beställning kommer att levereras.<br/><br/>Digitala produkter skickas direkt till den e-postadress du angav i beställningen.",
        },
      },
    },
  },
  "CUSTOMER_SERVICE": {
    "LINK": "/",
  },
  "OLD_BROWSER": {
    "INFO": "Hej! Du använder en utdaterad webbläsare. Vänligen <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">uppdatera din webbläsare</a> för att använda denna webbplats.",
  },
  "NO_JAVASCRIPT": {
    "LOGIN": "Du behöver aktivera Javascript för att kunna logga in.",
  },
  "CATEGORY": {
    "TOTAL_COUNT": "Visar %total produkt(er)",
    "EMPTY": "Det finns inga produkter att visa just nu",
    "LOAD_MORE": "Ladda fler",
    "PRODUCTS_IN_CATEGORY_TITLE": "Visar %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Visar 1 produkt i",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Visar %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Visar produkter i",
  },
  "404": {
    "TITLE": "Ledsen, sidan kan inte hittas.",
    "BACK": "Vill du gå tillbaka till",
    "BACK_LINK": "startsidan",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfel",
    "TEXT": "<strong>Behöver du kontakta oss?</strong><br/> Skicka ett mail till <a href=\"mailto:info@awardit.com\">info@awardit.com</a>",
  },
  "ADMINISTRATION": {
    "APPROVE_MEMBERS": "Godkänn medlemmar",
    "TITLE": "Administration",
  },
  "SEARCH": {
    "AND_ACTIVE_FILTERS": "och aktiva filter",
    "TITLE_PREFIX": "Sök: ",
    "PLACEHOLDER": "Vad letar du efter?",
    "ERROR_MINIMUM_CHARACTERS": "Ange minst %characters tecken",
    "EMPTY_SEARCH_RESULT": "Hittade ingen produkt som matchade",
    "PRODUCT_IN_SEARCH_RESULT": "Hittade 1 produkt som matchade",
    "PRODUCTS_IN_SEARCH_RESULT": "Hittade %itemsCount produkter som matchade",
    "SEARCHING_FOR": "Söker efter",
    "TYPE": {
      "CATEGORY": "Kategori",
      "BRAND": "Varumärke",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Stäng menyn",
    "SEARCH": "Sök",
    "CATEGORIES": "Kategorier",
    "CLOSE_CATEGORIES": "Stäng kategorier",
    "AFFILIATE_CATEGORIES": "Partners",
    "BRANDS": "Varumärken",
    "EARN": "Tjäna %pointsLabel",
    "REDEEM": "Använd dina %pointsLabel",
    "CLOSE_BRANDS": "Stäng varumärken",
    "WISHLIST": "Önskelista",
    "ACCOUNT": "Mitt konto",
    "CHECKOUT": "Kassan",
    "LOGOUT": "Logga ut",
    "DELIVER_TO": "Levereras till",
    "HOW_DOES_IT_WORK": "Hur funkar det?",
    "MY_ACCOUNT": "Mitt konto",
    "CUSTOMER_SERVICE": "Kundservice",
    "OUR_OFFER": "Vårt erbjudande",
    "NEWS": "Nyheter",
    "CATEGORY_SEE_ALL": "Se allt i %categoryName",
    "QUESTIONNAIRES": "Enkäter",
    "OFFER_LIST": "Erbjudanden",
    "LEADS": "Leads",
    "FAQ": "Vanliga frågor",
  },
  "FOOTER": {
    "CATEGORIES": "Kategorier",
    "LINKS": {
      "CONTACT": "Kontakt",
      "TITLE": "Länkar",
      "START": "Hem",
      "CATEGORIES": "Kategorier",
      "BRANDS": "Varumärken",
      "SEARCH": "Sök i sortimentet",
      "WISHLIST": "Önskelista",
      "MY_ACCOUNT": "Mitt konto",
      "GO_TO_MY_ACCOUNT": "Gå till mitt konto",
      "ALL_TRANSACTIONS": "Se alla transaktioner",
    },
    "NEED_HELP": {
      "TITLE": "Behöver du hjälp?",
      "CONTACT": "Kontakta oss",
      "DATE": "Helgfria vardagar 10-12",
      "COOKIES": "Hantera cookies",
    },
    "LEGAL": {
      "PRIVACY_POLICY": "Integritetspolicy",
      "TERMS": "Köpvillkor",
      "FAQ": "Vanliga frågor",
      "PRIVACY_NOTICE": "Privacy Notice",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Hem",
    "SEARCH": "Sökresultat för \"%query\"",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Lägg i varukorg",
    "DESCRIPTION": "Produktbeskrivning",
    "OUT_OF_STOCK": "Slut i lager",
    "OTHERS_ALSO_LIKED": "Andra gillade också",
    "OPTION_DROPDOWN_PLACEHOLDER": "Välj ett val",
    "LOGIN_TO_PURCHASE": "Logga in för att kunna handla",
    "CHANGE_IMAGE": "Byt bild",
    "INSUFFICIENT_FUNDS_HEADING": "Delbetalning",
    "INSUFFICIENT_FUNDS_BODY": "Har du inte tillräckligt med %pointsLabel för att köpa en produkt så kan du delbetala med pengar i kassan. Kom ihåg att minst %spendingLimit% av ordervärdet måste vara %pointsLabel.",
    "PERQUISITE_VALUE": "%value (premievärde exkl. moms vid förmånsbeskattning)",
    "RESTRICTION_PRODUCT_ADD_TO_CART": "Denna produkt går endast att köpa utan andra produkter i varukorgen, vill du ta bort produkterna som just nu ligger i kassan och gå vidare?",
    "RESTRICTION_PRODUCT_IN_CART": "Produkten du lägger till i varukorgen kan inte kombineras med produkterna som finns i varukorgen, vill du ta bort produkterna i varukorgen och lägga till denna?",
    "PRODUCTS_CANNOT_BE_COMBINED": "Produkterna kan inte kombineras",
    "SPECIFIC_POINT_DEFICIT_HEADING": "Du har inte tillräckligt med poäng för att köpa produkten",
    "SPECIFIC_POINT_DEFICIT_BODY": "Produkten kan bara köpas med poäng intjänade hos %partner och du saknar för närvarande %points",
    "ESTIMATED_DELIVERY": {
      "NORMAL": "Leverans inom 3-5 arbetsdagar",
      "VIRTUAL": "Skickas direkt med e-post",
    },
  },
  "STRIPE": {
    "CARD_NUMBER": "Kortnummer",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
    "PROCESSING": "Väntar",
  },
  "LEADS": {
    "CRITERIA": {
      "HEADER": "Kriterier",
      "LIST": {
        "FIRST": "Ni erbjuds möjlighet att lämna in upp till 10 leads per år",
      },
    },
    "POINTS": {
      "HEADER": "%pointsLabel för leads",
      "SUBHEADER": "Ett lead kan generera %pointsLabel i tre olika nivåer:",
      "LIST": {
        "FIRST": "Godkänt lead - 5 000 %pointsLabel",
        "SECOND": "Tipset genererar en ny samarbetspartner - 20 000 %pointsLabel",
        "THIRD": "Tipset (numera samarbetspartnern) startar 2 avtal - ytterligare 20 000 %pointsLabel",
      },
    },
    "LEVEL_ONE": {
      "HEADER": "Nivå 1 - 5 000 %pointsLabel",
      "SUBHEADER": "Ett lead måste uppfylla följande kriterier för att godkännas:",
      "LIST": {
        "FIRST": "Företaget säljer finansierbar utrustning",
        "SECOND": "Företaget bedriver B2B-försäljning",
        "THIRD": "Företaget är ej i kontakt med eller är befintlig samarbetspartner till Release Finans idag",
        "FOURTH": "Företaget säljer inte något av följande: entreprenadmaskiner eller fordon",
        "FIFTH": "Företaget kreditgodkänns av Release Finans",
        "SIXTH": "Företaget och kontaktpersonen har inte inkommit som lead tidigare",
      },
      "FOOTER": "%pointsLabel för ett godkänt inlämnat lead delas ut efter att vi kontrollerat att tipset uppfyller ovan angivna kriterier. Godkänns ert lead kommer %pointsLabel dyka upp i din saldovyn på portalen inom 15 arbetsdagar från att ni skickade in tipset.",
    },
    "LEVEL_TWO": {
      "HEADER": "Nivå 2 - 20 000 %pointsLabel",
      "SUBHEADER": "Ett lead måste uppfylla följande kriterier för att godkännas:",
      "LIST": {
        "FIRST": "Tipset genererar en ny samarbetspartner",
      },
      "FOOTER": "De %pointsLabel ni kan erhålla ifall ert lead genererat ett samarbete kan dröja. Detta beror helt enkelt på hur kontakten med den tipsade parten utvecklas. Definitionen av ett samarbete är att ett samarbetsavtal finns på plats. Har inget samarbete kunnat inledas inom 6 månader kommer ni meddelas detta och %pointsLabel uteblir.",
    },
    "LEVEL_THREE": {
      "HEADER": "Nivå 3 - ytterligare 20 000 %pointsLabel",
      "SUBHEADER": "Ett lead måste uppfylla följande kriterier för att godkännas:",
      "LIST": {
        "FIRST": "Samarbetspartnern startar 2 avtal",
      },
      "FOOTER": "Förutsättning för dessa %pointsLabel är att tidigare nivåer är uppfyllda, d.v.s. att ett samarbetsavtal finns på plats. Uppföljning och utdelning av %pointsLabel för 2 startade avtal sker månadsvis.",
    },
  },
  "VALIDATION": {
    "REQUIRED": "Detta fält är obligatoriskt",
    "EMAIL": "Vänligen fyll i en korrekt e‑postadress.",
    "PHONE": "Vänligen fyll i ett korrekt mobiltelefonnummer.",
    "NUMERIC": "Fältet får bara innehålla nummer",
    "LENGTH_LT": "Fältet innehåller för många tecken",
    "POSTCODE": "Vänligen fyll i ett korrekt postnummer. (XXX XX)",
    "PNR": "Vänligen fyll i ett korrekt personnummer. (ÅÅÅÅMMDD-XXXX)",
    "MATCH": "Lösenorden matchar inte",
    "NUMBER_GT": "Värdet måste vara större",
    "NUMBER_LT": "Värdet måste vara mindre",
    "DATE_INVALID_FORMAT": "Felaktigt format (YYYY-MM-DD)",
    "DATE_INVALID_DATE": "Ogiltigt datum",
    "NOT_A_VALID_GENDER": "Vänligen välj ett kön",
    "PASSWORD_VALIDATE": "Minst 6 tecken",
  },
  "MESSAGE": {
    "ERROR": "Fel",
    "SUCCESS": "Åtgärd utförd",
    "INSUFFICIENT_FUNDS": {
      "HEADING": "Ditt varukorgsvärde är för högt",
      "BODY": "Ditt varukorgsvärde är mer än %spendingLimit% av ditt %pointsLabel-saldo, du måste ta bort en eller flera produkter i din varukorg för att kunna slutföra köpet.",
      "POINTS_ONLY_BODY": "Ditt varukorgsvärde är högre än ditt %pointsLabel-saldo. En eller flera av dina produkter går inte att delbetala. Du måste ta bort en eller flera av dina produkter i din varukorg för att slutföra köpet.",
      "BUTTON_LEFT": "Hantera senare",
      "BUTTON_RIGHT": "Öppna varukorgen",
    },
    "MISSING_DETAILS": {
      "HEADING": "Åtgärd krävs",
      "NUMBER_NOT_FOUND": "Din profil saknar telefonnummer. Komplettera gärna dina uppgifter.",
      "DOB_NOT_FOUND": "Din profil saknar födelsedatum. Komplettera gärna dina uppgifter.",
      "FIRSTNAME_NOT_FOUND": "Din profil saknar förnamn. Komplettera gärna dina uppgifter.",
      "LASTNAME_NOT_FOUND": "Din profil saknar efternamn. Komplettera gärna dina uppgifter.",
      "EMAIL_NOT_FOUND": "Din profil saknar e-postadress. Komplettera gärna dina uppgifter.",
      "GENDER_NOT_FOUND": "Din profil saknar kön. Komplettera gärna dina uppgifter.",
      "ORGNR_NOT_FOUND": "Din profil saknar organisationsnummer. Komplettera gärna dina uppgifter.",
      "BUTTON_LEFT": "Hantera senare",
      "BUTTON_RIGHT": "Öppna mitt konto",
    },
    "BLOCKED": "Du kan för tillfället inte handla i shopen, vid frågor hör av dig till din kontaktperson.",
  },
  "ERROR": {
    "REQUIRED_TEXT_INPUT": "Detta fält är obligatoriskt",
  },
  "STATUS_CODE": {
    /* Client */
    "ERROR_NOT_LOGGED_IN": "Du verkar inte vara inloggad. Vänligen ladda om sidan och försök igen.",
    "FALLBACK": "Ett fel har uppstått. Vänligen försök igen.",
    "UNKNOWN": "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    "NETWORK_ERROR": "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",
    "PLACE_ORDER_CATCH": "Ett fel uppstod. Vänligen försök igen senare.",
    "CHANGE_PASSWORD_ERROR": "Ett fel uppstod. Vänligen försök igen senare.",
    "CHANGE_PASSWORD_SUCCESS": "Ditt lösenord är nu uppdaterat.",
    "SEND_ORDER_SUCCESS": "Din rapport har skickats.",
    "SEND_ORDER_ERROR": "Ett fel uppstod. Vänligen försök igen senare.",
    "MAIL_SENT": "Ett mail har skickats till dig.",
    "PASSWORD_UPDATE": "Lösenordet uppdaterades.",
    "TRANSFER_POINTS_ERROR": "Överföring misslyckades.",
    "TRANSFER_POINTS_SUCCESS": "%poinsLabel-överföring lyckades.",
    "ADD_PHONE_NUMBER": "Vänligen fyll i ett mobiltelefonnummer.",
    "POST_FORM_SUCCESS": "Ditt svar har skickats in.",
    "POST_FORM_ERROR": "Ditt svar kunde inte skickas, vänligen kontrollera att all nödvändig information är ifyllt.",
    "DELETE_FORM_SUCCESS": "Innehållet är borttaget.",
    "DELETE_FORM_ERROR": "Det gick inte att ta bort innehållet, var god försök igen",
    "BILLING_ADDRESS_ERROR": "Korrekta leveransuppgifter saknas, vänligen uppdatera dina uppgifter.",

    "REMOVE_MEMBER_ERROR": "Det uppstod ett fel när medlemmen skulle tas bort.",
    "REMOVE_MEMBER_SUCCESS": "Medlemmen är borttagen.",
    "ADD_MEMBER_ERROR": "Det uppstod ett fel när medlemmen skulle läggas till.",
    "ADD_MEMBER_SUCCESS": "Medlemmen är tillagd.",
    "UPDATE_MEMBER_ERROR": "Det uppstod ett fel vid uppdatering av medlemmen.",
    "UPDATE_MEMBER_SUCCESS": "Medlemmen är uppdaterad.",
    "GET_TRANSFERABLE_MEMBERS_ERROR": "Kunde inte hämta medlemmar, vänligen försök igen.",
    "TRANSFER_MEMBER_POINTS_ERROR": "Kunde inte överföra poäng, vänligen försök igen.",
    "TRANSFER_MEMBER_POINTS_SUCCESS": "Poängen har överförts.",

    "errorProductQuantityNotAvailable": "Det antal du angav finns inte tillgängligt just nu.",
    "errorProductNotInStock": "Produkten du försökte lägga i varukorgen finns dessvärre inte i lager just nu.",
    "errorProductMaxQuantity": "Det angivna antalet finns dessvärre inte i lager just nu.",
    "errorProductNotChosenVariant": "Vänligen välj %attributeLabel för att kunna lägga till produkten i din varukorg.",
    "termsNotAccepted": "Du måste godkänna köpvillkoren för att genomföra ditt köp.",

    "errorBillingAddressValidationFailed": "Vänligen kontrollera att du har fyllt i alla fält korrekt.", // test

    "errorInvalidLogin": "Felaktiga inloggningsuppgifter",
    "errorLoginFailed": "Ogiltigt användarnamn eller lösenord. Försök igen eller återställ ditt lösenord.",
    "errorMissingCustomer": "Kunden du försöker logga in som finns inte",
    "errorInsufficientawarditPointsForPurchase": "Kontots %pointsLabel-saldo är för lågt",
    "errorBlacklistedEmail": "Emailadressen du angav används redan. Vänligen ange en annan.",
    "errorInvalidEmailOrPassword": "Felaktiga inloggningsuppgifter",
    "errorResetPassword": "Lösenordet kunde inte återställas.",
    "updateCustomerEmail_errorNotLoggedIn": "Du verkar inte vara inloggad. Vänligen ladda om sidan och försök igen.",
    "updateCustomerEmail_errorInvalidEmail": "Emailadressen är inte godkänd. Vänligen kontrollera och försök igen.",
    "updateCustomerEmail_errorEmailExists": "Emailadressen du angav används redan. Vänligen ange en annan.",
    "QUIZ_ERROR_ANSWERED": "Du har redan svarat på detta Quiz",
    "SEND_QUIZ_SUCCESS": "Ditt svar har skickats",
    "SEND_IMAGE_ERROR": "Ditt foto kunde inte skickas",
    "SEND_IMAGE_SUCCESS": "Ditt foto har skickats",
    "TEAMPOT_ERROR": "Ett fel  har uppstått, %pointsLabel skickades ej",
    "TEAMPOT_SUCCESS": "%pointsLabel har skickats",
    "TEAMPOT_OPTION_ERROR": "Aktivitetsfältet kan inte vara tomt.",
    "TEAMPOT_POINTS_ERROR": "%pointsLabel-fältet kan inte vara tomt.",
    "TEAMPOT_RANK_ERROR": "Rankfältet kan inte vara tomt.",
    "TEAMPOT_USERS": "Medlemmar",

    "CART_NO_ITEMS": "Din varukorg är tom",

    "AFFILIATE": {
      "ACTIVATED": "Erbjudandet har blivit aktiverat",
      "NOT_ACTIVATED": "Erbjudandet kunde inte aktiveras, prova igen.",
      "ERROR_RELOADING": "Det uppdaterade erbjudandet kunde inte laddas in, vänligen ladda om sidan för att se förändringarna.",
    },

    "STRIPE_ERROR": {
      "YOUR_CARDS_EXPIRATION_DATE_IS_INCOMPLETE": "Ditt korts utgångsdatum är ofullständigt",
    },

    "TINK_CALLBACK": {
      "1": "Du har nu registrerat ditt medgivande och kan nu få %pointsLabel på dina butiksköp.", // Complete
      "2": "Vi kunde inte registrera ditt medgivande. Kontakta support.",
      "3": "Vi kunde inte hämta information från Tink. Försök igen senare.",
      "4": "Tink returnerade ett fel, försök igen senare.",
      "5": "Något gick fel vid anslutning till Tink, försök igen senare eller kontakta support.",
      "6": "Det är något fel med länken till Tink. Kontakta support.",
      "7": "Du har avbrutit autentiseringen.",
      "8": "Autentiseringen misslyckades.",
      "9": "Ett internt fel hos Tink. Kontakta support.",
      "10": "Ett temporärt fel hos Tink. Försök igen senare och kontakta support om felet kvarstår.",
      "UNKNOWN": "Något gick fel vid anslutning till Tink, försök igen senare eller kontakta support.",
      "GENERIC_ERROR": "Ett fel inträffade. Vänligen försök igen",
    },

    "REGISTER_MEMBER": {
      "SUCCESS_ACTIVATE": "Ditt konto är nu aktiverat!",
      "SUCCESS_REG": "Dina användaruppgifter är nu registrerade, vänligen kontrollera din e-post för att aktivera ditt konto.",
    },

    "RECOMMEND_MEMBER": {
      "ERROR": "Det gick inte att skicka rekomendationen till användaren.",
    },

    "ITEM_REMOVED_FROM_CART": "%name togs bort från varukorgen eftersom att den inte längre finns i lager.",

    "MEMBERGROUP": {
      "MEMBER_UPDATED": "Gruppmedlemmen är nu uppdaterad.",
      "MEMBER_ADDED": "Medlem tillagd i gruppen.",
      "MEMBER_REMOVED": "Medlem borttagen från gruppen.",
    },
  },

  "ADDITIONAL_MEMBER_NUMBERS": {
    "TABLE": {
      "NAME": "Namn",
      "NUMBER": "Kundnummer",
    },
  },

  "MOTIVATION_POT": {
    "MOTIVATION_POT_TITLE": "Teampott",
    "SUB_TITLE": "Här kan du dela ut %pointsLabel till de anställda",
    "USERS_HEADER": "Utdelning av %pointsLabel",
    "CHOOSE_ALL": "Välj alla",
    "POINTS_OF_CHOSEN": "%pointsLabel (x %userAmount valda)",
    "POINTS": "%pointsLabel",
    "POINTS_BALANCE_ERROR": "Ditt %pointsLabel-saldo räcker tyvärr inte till",
    "SHARED_POINTS": "Utdelat",
    "COMMENT": "Kommentar",
    "POINTS_AVAILABLE": "Tillgängliga %pointsLabel",
    "POINTS_HANDED_OUT": "%pointsLabel som delas ut",
    "POINTS_LEFT_IN_POT": "%pointsLabel kvar i potten",
    "POINTS_HEADER": "%pointsLabel-pott",
    "PERIOD": "Period",
    "TOTAL_POINTS": "Total %pointsLabel-pott",
    "POINTS_LEFT": "%pointsLabel kvar",
    "PREVIOUS_HANDED_OUT_HEADER": "Tidigare utdelningar",
    "ACTIVITY": "Aktivitet",
    "MEMBER_COUNT": "Antal medlemmar",
    "RANK": "%pointsLabel",
    "RANK_OF_CHOSEN": "Rang (x %userAmount valda)",
    "DATE": "Datum",
    "RECEIVERS": "Mottagare",
    "CHOOSE_AVTIVITY": "Välj aktivitet…",
    "VALIDATION_DATE": "%fromDate till %toDate",
  },
  "VERIFY_EMAIL": {
    "MAIL_ADDRESS": "%email",
    "FIRST_STEP": {
      "TEXT": {
        "FIRST_PART": "Hej, ingen fara, vi behöver bara verifiera att det här är din e-post: ",
      },
    },
    "SECOND_STEP": {
      "HEADER_TEXT": {
        "FIRST_ROW": "Du ska nu ha fått ett mail på ",
        "SECOND_ROW": "Klicka på länken i mailet för att återaktivera ditt konto.",
      },
      "TEXT": {
        "NO_MAIL": {
          "HEADER": "Kommer mailet inte fram?",
          "TEXT": "Skicka återaktiverings-mailet igen genom att klicka ",
          "HERE": "här",
        },
      },
    },
  },
  "MEMBERGROUPVIEW": {
    "PARTICIPANT": "Deltagare",
    "STATUS": "Status",
    "GROUP_MEMBER": "Gruppmedlem",
    "POINTS": "%pointsLabel",
    "TRANSACTIONS": "Se transaktioner",
    "REMOVE": "Ta bort",
    "UPDATE": "Uppdatera",
    "POINTSOPTION_0": "Medlemmen kan se och lösa in endast egna %pointsLabel",
    "POINTSOPTION_1": "Medlemmen kan endast se gruppens %pointsLabel, men kan lösa in egna %pointsLabel om den har några",
    "POINTSOPTION_2": "Medlemmen kan se och lösa in alla %pointsLabel",
    "YES": "Ja",
    "NO": "Nej",
    "REMOVE_TITLE": "Ta bort gruppmedlem",
    "REMOVE_CONFIRM": "Vill du verkligen ta bort gruppmedlemmen \"%member\"?",
    "ADD_MEMBER": "Lägg till ny medlem",
    "ORGNR": "Organisationsnummer",
    "LINK": "Hantera medlemsgrupp",
  },
};
